import upAndAway from "./images/recommendedBy/up-and-away.png";
import budding from "./images/recommendedBy/budding-communicators.png";
import beeConnected from "./images/recommendedBy/bee-connected.png";
import charlotte from "./images/recommendedBy/charlotte.png";
import chatterTots from "./images/recommendedBy/chatter-tots.png";
import communikate from "./images/recommendedBy/communikate.png";

export const recommendations = [
  {
    name: "Katie Sherman, Speech and Language Therapist",
    website: "https://www.upandawayspeech.co.uk",
    image: upAndAway,
  },
  {
    name: "Kate Burgess, Speech and Language Therapist",
    website: "https://www.wecancommunikate.co.uk",
    image: communikate,
  },
  {
    name: "Liz Reeves, Speech and Language Therapist",
    website: "https://www.buddingcommunicators.co.uk",
    image: budding,
  },
  {
    name: "Charlotte Hall, Speech & Language Therapist ",
    website: "https://www.speechtherapywithcharlotte.com",
    image: charlotte,
  },
  {
    name: "Jen Moore, Speech and Language Therapist ",
    website: "https://www.beeconnectedslt.co.uk",
    image: beeConnected,
  },
  {
    name: "Dr Abbie Moran",
    website: "https://www.instagram.com/chattertotsslt/",
    image: chatterTots,
  },
];
